body {
  background: rgb(34,34,34);
  background: linear-gradient(180deg, rgba(34,34,34,1) 0%, rgba(68,68,68,1) 100%);
  margin: 0;
  padding: 0;
  height: 100%;

  header {
    background: #fff;
    display: flex;
    align-items: center;

    h1 {
      color: #111;
      margin: 0;
      font-size: 24px;
      text-transform: uppercase;
      padding: 10px;

      span {
        color: #808080;
      }
    }
    a {
      margin-left: auto;
      margin-right: 14px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #444;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;

      &:hover {
        color: #222;

        .emailIcon {
          fill: #222;
        }
      }

      .emailIcon {
        height: 18px;
        fill: #808080;
        margin-left: 8px;
      }
    }
  }

  footer {
    display: flex;
    width: 100%;
    background: #222;
    color: #fff;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    p {
      font-size: 12px;
      color: #808080;
    }
  }

  #grid {
    display: flex;

    .column {
      flex: 20%;
      max-width: 25%;
      padding: 0 4px;

      img {
        margin-top: 8px;
        vertical-align: middle;
        width: 100%;
      }
    }

    /* Responsive layout - makes a two column-layout instead of four columns */
    @media screen and (max-width: 800px) {
      .column {
        flex: 50%;
        max-width: 50%;
      }
    }

    /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 600px) {
      .column {
        flex: 100%;
        max-width: 100%;
      }
    }
  }
}
